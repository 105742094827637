import './editor.scss';
import './style.scss';

import Flickity from 'react-flickity-component';

const { __ } = wp.i18n;
const { InspectorControls, InnerBlocks } = wp.blockEditor;
const { registerBlockType } = wp.blocks;
const { withSelect } = wp.data;
const { PanelBody, RangeControl, TextControl, ToggleControl } = wp.components;
const { decodeEntities } = wp.htmlEntities;
const { createElement, RawHTML } = wp.element;

registerBlockType('pixelbound/latest-testimoinals-carousel', {
	title: __('Latest Testimonials Carousel', 'pixelbound_testimonials'),
	icon: 'testimonial',
	keywords: [
		__('Block', 'pixelbound_testimonials'),
		__('Testimonials', 'pixelbound_testimonials'),
		__('Carousel', 'pixelbound_testimonials'),
	],
	attributes: {
		numberOfTestimonials: {
			type: 'number',
			default: 6,
		},
		numberOfTestimonialsToShow: {
			type: 'number',
			default: 1,
		},
		spacingTop: {
			type: 'number',
			default: 2,
		},
		spacingBottom: {
			type: 'number',
			default: 2,
		},
	},
	edit: withSelect((select, props) => {
		const { attributes } = props;
		const { numberOfTestimonials } = attributes;

		const testimonials = select('core').getEntityRecords(
			'postType',
			'pb_testimonial',
			{
				per_page: numberOfTestimonials,
				orderby: 'date',
				order: 'desc',
			}
		);
		return { testimonials };
	})((props) => {
		const { testimonials, className, attributes, setAttributes } = props;
		const {
			numberOfTestimonials,
			numberOfTestimonialsToShow,
			spacingTop,
			spacingBottom,
		} = attributes;

		// Construct inline styles object
		let inlineStyles = {};
		spacingTop ? (inlineStyles.marginTop = `${spacingTop}em`) : '0em';
		spacingBottom ? (inlineStyles.marginBottom = `${spacingBottom}em`) : '0em';

		return (
			<>
				<InspectorControls>
					<PanelBody
						title={__('Settings', 'pixelbound_testimonials')}
						initialOpen={true}
					>
						<RangeControl
							label={__('Number Of Testimonials', 'pixelbound_testimonials')}
							help={__(
								'How many testimonials should be included in the carousel.',
								'pixelbound_testimonials'
							)}
							value={numberOfTestimonials}
							onChange={(value) =>
								setAttributes({ numberOfTestimonials: value })
							}
							step={1}
							min={1}
							max={12}
						/>
						<RangeControl
							label={__(
								'Number Of Testimonials to Show',
								'pixelbound_testimonials'
							)}
							help={__(
								'How many testimonials should be visible per slide.',
								'pixelbound_testimonials'
							)}
							value={numberOfTestimonialsToShow}
							onChange={(value) =>
								setAttributes({ numberOfTestimonialsToShow: value })
							}
							step={1}
							min={1}
							max={4}
						/>
						<RangeControl
							label={__('Spacing Top', 'pixelbound_testimonials')}
							value={spacingTop}
							onChange={(value) => setAttributes({ spacingTop: value })}
							step={0.1}
							min={0}
							max={20}
						/>
						<RangeControl
							label={__('Spacing Bottom', 'pixelbound_testimonials')}
							value={spacingBottom}
							onChange={(value) => setAttributes({ spacingBottom: value })}
							step={0.1}
							min={0}
							max={20}
						/>
					</PanelBody>
				</InspectorControls>

				{testimonials && testimonials.length > 0 ? (
					<div className={className} style={inlineStyles}>
						<Flickity
							className={`wp-block-pixelbound-latest-testimonials-carousel show-${numberOfTestimonialsToShow}-testimonials`}
							elementType={'div'}
							options={{
								groupCells: true,
								cellAlign: 'left',
								contain: true,
								pageDots: false,
							}}
						>
							{testimonials.map((testimonial) => (
								<article
									className={
										'wp-block-pixelbound-latest-testimonials-carousel__testimonial'
									}
									key={testimonial.id}
								>
									<RawHTML>
										{decodeEntities(testimonial.content.rendered)}
									</RawHTML>
								</article>
							))}
						</Flickity>
					</div>
				) : (
					<div>
						{testimonials
							? __('No testimonials to display.', 'pixelbound_testimonials')
							: __('Loading...', 'pixelbound_testimonials')}
					</div>
				)}
			</>
		);
	}),
	save: () => {
		return '';
	},
});
